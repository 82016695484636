import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        width: '100%',
        maxWidth: 1024,
        margin: 'auto',
        marginTop: 31,
        padding: '0 25px 25px 25px',
        display: 'flex',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('1250')]: {
            marginTop: 60,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 72,
            padding: '12px 0px',
        },
    },
    loadingContainer: {
        margin: 'auto',
        height: '400px',
        marginTop: 31,
        '& h1': {
            textAlign: 'center',
            margin: '0.67em auto 8px auto',
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
            },
        },
        '& div': {
            height: 'auto',
            margin: 'auto',
            width: 'fit-content',
        },
        [theme.breakpoints.down('1250')]: {
            marginTop: 250,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 72,
            padding: '12px 0px',
        },
    },
    sliderItem: {
        width: '100%',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default useStyles;
