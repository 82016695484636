/* eslint-disable no-unused-vars */
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CenterAbsolute, FlexColumn, FlexRow } from '@theme_mixins';

const useStyles = makeStyles({
    root: {
        margin: 0,
        marginBottom: 10,
        ...FlexColumn,
        '& .radio-container': {
            justifyContent: 'center',
            '& div': {
                margin: '5px',
            },
        },
    },
    column: {
        ...FlexColumn,
    },
    row: {
        ...FlexRow,
    },
    error: {
        marginBottom: 0,
    },
});

export default useStyles;
