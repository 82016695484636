import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import MagezonLink from '@core_modules/cms/components/cms-renderer/magezon/MagezonLink';
import Countdown from 'react-countdown';

const CircleCountdown = dynamic(import('@core_modules/cms/components/cms-renderer/magezon/MagezonCountdown/components/circle'));

const CustomCountdown = (props) => {
    const {
        custom_type, value,
        number_spacing, number_background_color, number_border_radius, number_padding, number_color, number_size,
        text_inline, text_size, text_color,
    } = props;
    return (
        <>
            <div className="mgz-countdown-item">
                <div className={`mgz-countdown-number mgz-countdown-${custom_type}`}>
                    <div className="mgz-countdown-unit">
                        <span className="mgz-countdown-unit-number">{`${value} `}</span>
                    </div>
                </div>
                <div className="mgz-countdown-unit-label">{`${custom_type[0].toUpperCase()}${custom_type.slice(1)}`}</div>
            </div>
            <style jsx>
                {
                    `
                        .mgz-countdown-item {
                            display: inline-block;
                            margin: ${number_spacing ? `${number_spacing}px` : '10px'};
                            width: 50px;
                            text-align: center;
                        }
                        .mgz-countdown-number {
                            border: 1px solid #fafafa;
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                            margin-bottom: 5px;
                        }
                        .mgz-countdown-unit {
                            text-align: center;
                            background-color: ${number_background_color};
                            border-radius: ${number_border_radius}px;
                            padding: ${number_padding};
                        }
                        .mgz-countdown-unit-number {
                            color: ${number_color ?? '#000'};
                            font-size: ${number_size ?? '24px'};
                            font-weight: bold;
                        }
                        .mgz-countdown-unit-label {
                            display: ${text_inline ? 'inline-block' : 'block'};
                            font-size: ${text_size ?? '12px'};
                            color: ${text_color};
                            font-weight:bold;
                            text-transform: uppercase;
                        }
                    `
                }
            </style>
        </>
    );
};

const renderer = ({
    days, hours: rendererHours, minutes: rendererMinutes, seconds, completed, props,
}) => {
    const {
        show_separator, separator_type, separator_color, separator_size, CompleteContent,
        sub_heading_text, heading_text, heading_font_size, heading_color,
        sub_heading_font_size, sub_heading_color, completeProps,
        link_text, link_url, link_color,
    } = props;
    const separator = separator_type === 'line' ? '|' : ':';
    if (completed && completeProps) {
        return <CompleteContent {...completeProps} />;
    }

    return (
        <>
            <div className="mgz-countdown">
                <div className="mgz-countdown-heading-wrapper">
                    <div className="mgz-countdown-subheading">{sub_heading_text}</div>
                    <div className="mgz-countdown-heading">{heading_text}</div>
                </div>
                <div className="mgz-countdown-counter">
                    <CustomCountdown custom_type="hari" value={days} {...props} />
                    {show_separator && <span className="mgz-countdown-separator">{separator}</span>}
                    <CustomCountdown custom_type="jam" value={rendererHours} {...props} />
                    {show_separator && <span className="mgz-countdown-separator">{separator}</span>}
                    <CustomCountdown custom_type="menit" value={rendererMinutes} {...props} />
                    {show_separator && <span className="mgz-countdown-separator">{separator}</span>}
                    <CustomCountdown custom_type="detik" value={seconds} {...props} />
                </div>
                { !completeProps && (
                    <div className="mgz-countdown-link">
                        {link_url && (
                            <MagezonLink link={link_url}>
                                <span style={{
                                    display: 'inline-block',
                                    color: link_color || '#fff',
                                    background: '#001E62',
                                    borderRadius: '8px',
                                    padding: '5px 40px',
                                    fontSize: '18px',
                                }}
                                >
                                    {link_text || ''}
                                </span>
                            </MagezonLink>
                        )}
                    </div>
                )}
            </div>
            <style jsx>
                {`
                    .mgz-countdown {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 0;
                        margin: 20px 0;
                    }
                    .mgz-countdown-separator {
                        color: ${separator_color};
                        font-size: ${separator_size ? `${separator_size}px` : '14px'};
                    }
                    .mgz-countdown-counter {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .mgz-countdown-heading {
                        font-size: ${heading_font_size ? `${heading_font_size}px` : '16px'};
                        font-weight: bold;
                        color: ${heading_color};
                    }
                    .mgz-countdown-subheading {
                        font-size: ${sub_heading_font_size ? `${sub_heading_font_size}px` : '14px'};
                        color: ${sub_heading_color};
                    }
                    .mgz-countdown-heading-wrapper {
                        text-align: center;
                    }
                    .mgz-countdown-link {
                        margin-top: 10px;
                    }
                `}
            </style>
        </>
    );
};

const MagezonCountdown = (props) => {
    // prettier-ignore
    const {
        layout,
        year, month, day,
        hours, minutes,
    } = props;

    return (
        <>
            {layout === 'numbers' ? (
                <Countdown
                    date={dayjs(new Date(year, month - 1, day, hours, minutes)).toDate()}
                    renderer={renderer}
                    {...props}
                />
            ) : (
                <CircleCountdown
                    date={dayjs(new Date(year, month - 1, day, hours, minutes))}
                    {...props}
                />
            )}
        </>
    );
};

export default MagezonCountdown;
