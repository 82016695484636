/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import Typograpy from '@common_typography';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import useStyles from '@common_optionconfigurable/style';

const SelectOption = (props) => {
    const {
        value, selected, onChange, className = '', disabled = false, thumbnail, content = '',
        labelClassName = '', parentDisabled, attributeCode, isOutOfStock,
    } = props;
    const styles = useStyles();
    const isOss = isOutOfStock(attributeCode, value);
    const colorIsBlack = content.toLowerCase() === 'black' || content.toLowerCase() === '#000000';
    const thumbnailIsExist = thumbnail && thumbnail !== '';
    const containerStyle = selected && !disabled
        ? classNames(
            styles.container,
            colorIsBlack && !thumbnailIsExist
                ? classNames(styles.borderedSecondary, 'selected')
                : classNames(thumbnailIsExist ? styles.borderedThumbnail : styles.bordered, 'selected'),
            className,
        )
        : classNames(styles.container, className);
    const labelStyle = selected
        ? classNames(styles.label, styles.labelActive, labelClassName)
        : classNames(styles.label, labelClassName);
    let customStyle = {
        border: `1px solid ${selected ? PRIMARY : GRAY_PRIMARY}`,
    };
    let childContent = <Typograpy className={labelStyle}>{content}</Typograpy>;
    if (content.includes('#')) {
        customStyle = {
            backgroundColor: content,
        };
        if (content === '#ffffff') {
            customStyle.border = `1px solid ${selected ? PRIMARY : GRAY_PRIMARY}`;
        }
        childContent = '';
    }

    if (thumbnailIsExist) {
        customStyle = {
            backgroundImage: `url(${thumbnail})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };
        childContent = '';
    }

    if (isOss || disabled) {
        customStyle = {
            ...customStyle,
            cursor: 'unset',
        };
    }

    if (!content.includes('#') && content.length > 2) {
        const newWidth = ((content.length - 2) * 10) + 30;
        customStyle.width = `${newWidth}px`;
    }

    const handleChange = () => {
        // eslint-disable-next-line no-unused-expressions
        (!disabled && !isOss) && onChange(value);
    };
    return (
        <div style={customStyle} className={containerStyle} onClick={handleChange}>
            {(disabled || isOss || parentDisabled) && (<div className={styles.disabledBox} />) }
            {childContent}
        </div>
    );
};

export default SelectOption;
