import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    noMargin: {
        margin: 0,
        '&.price_text': {
            textAlign: 'center',
            fontSize: '14px',
            padding: '10px',
            fontWeight: '700',
            color: '#001E62',
        },
    },
}));

export default useStyles;
