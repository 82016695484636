import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        position: 'relative',
        left: 0,
        top: -29,
        marginTop: 0,
        [theme.breakpoints.down('1250')]: {
            marginTop: 170,
            top: 0,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },

    headerContainer: {
        background: '#e3e5f3',
        margin: '0 auto',
        padding: '12px 0px',
        [theme.breakpoints.down('md')]: {
            padding: '12px 40px',
        },
    },
    headerInnerContainer: {
        maxWidth: 1024,
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '& .title': {
            justifyContent: 'center',
            fontSize: 18,
            fontWeight: 'bold',
            color: '#001e63',
        },
        '& .back-container': {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            left: 0,
            marginTop: 2,
            '& .back-icon': {
                fontSize: 18,
                cursor: 'pointer',
                color: '#001e63',
            },
            '& .back-text': {
                fontWeight: 'bold',
                fontSize: 16,
                lineHeight: 1,
                cursor: 'pointer',
                color: '#001e63',
                [theme.breakpoints.down('1024')]: {
                    display: 'none',
                },
            },
        },
    },

    defaultTitle: {
        display: 'flex',
        alignItems: 'center',
        '& .logo': {
            width: 80,
        },
        '& span': {
            marginTop: 4,
            lineHeight: 1,
        },
        '& span.divider': {
            marginLeft: 16,
            marginRight: 16,
            fontSize: 32,
            fontWeight: 100,
        },
    },

    progressBar: {
        background: '#001e63',
        color: 'white',
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'right',
        paddingRight: 8,
        borderRadius: '0 12px 12px 0',
    },
}));

export default useStyles;
