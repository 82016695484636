/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classNames from 'classnames';
import Typography from '@common_typography';
import ButtonQty from '@common_buttonqty';

import useStyles from '@plugin_optionitem/components/Footer/style';

const Button = dynamic(() => import('@common_button'), { ssr: true });

const ConfigurableView = (props) => {
    const {
        loading,
        disabled,
        showQty = true,
        handleAddToCart,
        qty,
        setQty,
        t,
        showAddToCart = true,
        customStyleBtnAddToCard = '',
        labelAddToCart = '',
        maxQty = 10000,
        customButton,
        customQty = false,
        freeItemsData,
        isAsicsSpecial,
        isLogin,
        urlPdp,
    } = props;
    const styles = useStyles();

    if (customButton) {
        return customButton;
    }
    const addToCartForAsicsSpecial = isAsicsSpecial && !isLogin ? false : true;

    return (
        <>
            {showQty && !isAsicsSpecial && (
                <div className={classNames(styles.qty, 'product-OptionItem-qty')}>
                    <Typography type="bold" variant="span">
                        {t('common:title:qty')}
                    </Typography>
                    <ButtonQty value={qty} onChange={setQty} max={customQty ? freeItemsData.quantity : maxQty} disabled={disabled} />
                </div>
            )}
            {showAddToCart && (
                isAsicsSpecial ? (
                    addToCartForAsicsSpecial ? (
                        <div className={styles.btnAddToCardContainer}>
                            <Button
                                id="plugin-addToCart-btn"
                                className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                                color="primary"
                                onClick={handleAddToCart}
                                loading={loading}
                                disabled={disabled}
                            >
                                <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                                    {labelAddToCart || t('product:addToCart')}
                                </Typography>
                            </Button>
                        </div>
                    ) : (
                        <Typography
                            variant="p"
                            size="16"
                        >
                            <Typography
                                variant="span"
                                className="clear-margin-padding"
                                size="16"
                            >
                                {'Untuk melakukan pembelian pada product ini silahkan '}
                            </Typography>
                            <Link href={`/customer/account/login/?redirect=/${urlPdp}`}>
                                <Typography
                                    variant="span"
                                    decoration="underline"
                                    className={classNames(styles.productLimitedInfoLoginLink, 'clear-margin-padding')}
                                    size="16"
                                >
                                    Masuk
                                </Typography>
                            </Link>
                            <Typography
                                variant="span"
                                className="clear-margin-padding"
                                size="16"
                            >
                                {' '}
                                {' atau '}
                            </Typography>
                            <Link href={`/customer/account/create/?redirect=/${urlPdp}`}>
                                <Typography
                                    variant="span"
                                    decoration="underline"
                                    className={classNames(styles.productLimitedInfoLoginLink, 'clear-margin-padding')}
                                    size="16"
                                >
                                    Buat Akun
                                </Typography>
                            </Link>
                        </Typography>
                    )
                ) : (
                    <div className={styles.btnAddToCardContainer}>
                        <Button
                            id="plugin-addToCart-btn"
                            className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                            color="primary"
                            onClick={handleAddToCart}
                            loading={loading}
                            disabled={disabled}
                        >
                            <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                                {labelAddToCart || t('product:addToCart')}
                            </Typography>
                        </Button>
                    </div>
                )
            )}
        </>
    );
};

export default ConfigurableView;
