/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getCmsPage = gql`
    query($identifier: String!) {
        cmsPage(identifier: $identifier) {
            title
            content
        }
    }
`;

export const getCmsBlocks = gql`
  query($identifiers: [String]) {
      cmsBlocks(identifiers: $identifiers) {
          items {
              title
              content
          }
      }
  }
`;

export const getQuizByGroupId = gql`
    query getQuizByGroupId($quizGroupId: Int) {
        getQuizByGroupId(quiz_group_id: $quizGroupId) {
            group_id
            status
            title
            quiz {
                another_info
                attribute
                description
                header
                layout
                position
                quiz_id
                required
                status
                title
                type
                answers {
                    description
                    image
                    note
                    value
                }
            }
        }
    }
`;

export const getQuizAnswered = gql`
query getQuizAnswered($group_id: Int!){
    getQuizCustomerAnswered(group_id: $group_id) {
      customer_email
      customer_id
      group_id
      quiz_answer {
        quiz_id
        question
        answer{
          description
          value
        }
      }
      sku_list
    }
  }  
`;

export const setQuizAnswered = gql`
    mutation setQuizAnswered(
        $group_id: Int!,
        $quiz_answer: [QuizCustomerAnsweredQuizInput],
        $sku_list: [String]
        ){
        setQuizCustomerAnswered(
            input: {
            group_id: $group_id,
            quiz_answer: $quiz_answer, 
            sku_list: $sku_list
            }
        ) {
            customer_id
            customer_email
            group_id
            quiz_answer {
                quiz_id
                question
                answer {
                    description
                    value
                }
            }
            sku_list
        }
    }
`;
