/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from '@core_modules/productfinder/services/graphql/schema';

export const getCmsBlocks = (variables) => useLazyQuery(Schema.getCmsBlocks, {
    variables,
});

export const getCmsPage = (variables) => useQuery(Schema.getCmsPage, {
    variables,
});

export const getQuizByGroupId = (variables) => useLazyQuery(Schema.getQuizByGroupId, {
    variables,
});

export const getQuizAnswered = (variables) => useLazyQuery(Schema.getQuizAnswered, {
    variables,
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
});

export const setQuizAnswered = (variables) => useMutation(Schema.setQuizAnswered, {
    variables,
    context: {
        request: 'internal',
    },
});
