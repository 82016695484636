/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import dynamic from 'next/dynamic';
import Button from '@common_button';
import PriceFormat from '@common_priceformat';
import RatingStar from '@common_ratingstar';
import Banner from '@common_slick/BannerThumbnail';
import Typography from '@common_typography';
import DesktopOptions from '@core_modules/product/pages/default/components/OptionItem/DesktopOptions';
import ItemShare from '@core_modules/product/pages/default/components/SharePopup/item';
import { getSeller, getCmsBlocksRaw, getCmsBlocksLazy } from '@core_modules/theme/services/graphql';
import { getHost } from '@helper_config';
import { useTranslation } from '@i18n';
import { customCmsBlock } from '@config';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '@plugin_productitem/components/QuickView/style';
import WeltpixelLabel from '@plugin_productitem/components/WeltpixelLabel';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { getLoginInfo } from '@helper_auth';
import parse from 'html-react-parser';

const CountDown = dynamic(() => import('@core_modules/product/pages/default/components/CountDown'), { ssr: true });

const ProductWrapperAction = (props) => {
    const {
        keyProduct, data, setStockStatus, setBanner, price, setPrice,
        setAdditionalPrice, additionalPrice, loadingCmsCountdown, dataCmsRaffle, loadingCmsRaffle,
    } = props;
    const styles = useStyles();
    const { dataStock } = props;
    const { t } = useTranslation(['common', 'product', 'catalog']);
    const route = useRouter();
    let productKey;
    for (let i = 0; i < data.items.length; i += 1) {
        if (keyProduct === data.items[i].url_key) {
            productKey = [i];
        }
    }

    const product = data?.items[productKey];
    let isLogin = '';
    const isRaffe = product?.raffle === 1 ? true : false;
    if (typeof window !== 'undefined') isLogin = getLoginInfo();
    const isAsicsSpecial = product?.is_asics_special === true ? true : false;
    const addToCartForAsicsSpecial = isAsicsSpecial && !isLogin ? false : true;

    // Customizable Options
    const [customizableOptions, setCustomizableOptions] = React.useState([]);
    const [errorCustomizableOptions, setErrorCustomizableOptions] = React.useState([]);

    const checkCustomizableOptionsValue = async () => {
        if (product.options && product.options.length > 0) {
            const requiredOptions = product.options.filter((op) => op.required);
            if (requiredOptions.length > 0) {
                if (customizableOptions.length > 0) {
                    let countError = 0;
                    const optionsError = [];
                    for (let idx = 0; idx < requiredOptions.length; idx += 1) {
                        const op = requiredOptions[idx];
                        const findValue = customizableOptions.find((val) => val.option_id === op.option_id);
                        if (!findValue) {
                            optionsError.push(op);
                            countError += 1;
                        }
                    }
                    if (countError > 0) {
                        await setErrorCustomizableOptions(optionsError);
                        return false;
                    }
                    return true;
                }
                await setErrorCustomizableOptions(requiredOptions);

                return false;
            }
            return true;
        }
        return true;
    };

    React.useEffect(() => {
        if (errorCustomizableOptions && errorCustomizableOptions.length > 0) {
            // eslint-disable-next-line consistent-return
            const errorCustomizable = errorCustomizableOptions.filter((err) => {
                const findValue = customizableOptions.find((op) => op.option_id === err.option_id);
                return !findValue;
            });
            setErrorCustomizableOptions(errorCustomizable);
        }
    }, [customizableOptions]);

    if (loadingCmsCountdown || loadingCmsRaffle) {
        return <></>;
    }
    if (isRaffe) {
        return (
            <>
                {dataCmsRaffle ? (
                    <>
                        <div className="tnc-cms">
                            {dataCmsRaffle?.cmsBlocks?.items[0]?.content ? parse(dataCmsRaffle?.cmsBlocks?.items[0]?.content) : ''}
                        </div>
                        <Link href={`/${product.url_key}`}>
                            <a target="_blank">
                                <Typography
                                    variant="span"
                                    decoration="underline"
                                    className={classNames('clear-margin-padding')}
                                    size="14"
                                >
                                    Selengkapnya
                                </Typography>
                            </a>
                        </Link>
                    </>
                ) : null}
                <Button
                    className={classNames(styles.btnGoToProduct)}
                    color="primary"
                    align="left"
                    onClick={() => route.push(`/${product.url_key}`)}
                >
                    <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                        {t('product:goToProduct')}
                    </Typography>
                </Button>
                <div className={classNames(styles.desktopShareIcon)}>
                    <Typography className={styles.shareTitle} variant="title">
                        {t('product:shareTitle')}
                    </Typography>
                    <div className="content-share">
                        <ItemShare link={getHost() + route.asPath} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="hidden-mobile">
            {isAsicsSpecial ? (
                addToCartForAsicsSpecial ? (
                    <DesktopOptions
                        price={price}
                        t={t}
                        data={product}
                        dataStock={dataStock}
                        setBanner={setBanner}
                        setPrice={setPrice}
                        setStockStatus={setStockStatus}
                        setAdditionalPrice={setAdditionalPrice}
                        customizableOptions={customizableOptions}
                        setCustomizableOptions={setCustomizableOptions}
                        errorCustomizableOptions={errorCustomizableOptions}
                        checkCustomizableOptionsValue={checkCustomizableOptionsValue}
                        additionalPrice={additionalPrice}
                        isAsicsSpecial={isAsicsSpecial}
                        isLogin={isLogin}
                    />
                ) : (
                    <Typography
                        variant="p"
                        size="16"
                    >
                        <Typography
                            variant="span"
                            className="clear-margin-padding"
                            size="16"
                        >
                            {'Untuk melakukan pembelian pada product ini silahkan '}
                        </Typography>
                        <Link href="/customer/account/login">
                            <Typography
                                variant="span"
                                decoration="underline"
                                className={classNames(styles.productLimitedInfoLoginLink, 'clear-margin-padding')}
                                size="16"
                            >
                                Masuk
                            </Typography>
                        </Link>
                        <Typography
                            variant="span"
                            className="clear-margin-padding"
                            size="16"
                        >
                            {' '}
                            {' atau '}
                        </Typography>
                        <Link href="/customer/account/create">
                            <Typography
                                variant="span"
                                decoration="underline"
                                className={classNames(styles.productLimitedInfoLoginLink, 'clear-margin-padding')}
                                size="16"
                            >
                                Buat Akun
                            </Typography>
                        </Link>
                    </Typography>
                )
            ) : (
                <DesktopOptions
                    price={price}
                    t={t}
                    data={product}
                    dataStock={dataStock}
                    setBanner={setBanner}
                    setPrice={setPrice}
                    setStockStatus={setStockStatus}
                    setAdditionalPrice={setAdditionalPrice}
                    customizableOptions={customizableOptions}
                    setCustomizableOptions={setCustomizableOptions}
                    errorCustomizableOptions={errorCustomizableOptions}
                    checkCustomizableOptionsValue={checkCustomizableOptionsValue}
                    additionalPrice={additionalPrice}
                />
            )}
            <Button
                className={classNames(styles.btnGoToProduct)}
                color="primary"
                align="left"
                onClick={() => route.push(`/${product.url_key}`)}
            >
                <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                    {t('product:goToProduct')}
                </Typography>
            </Button>
            <div className={classNames(styles.desktopShareIcon)}>
                <Typography className={styles.shareTitle} variant="title">
                    {t('product:shareTitle')}
                </Typography>
                <div className="content-share">
                    <ItemShare link={getHost() + route.asPath} />
                </div>
            </div>
        </div>
    );
};

const QuickView = (props) => {
    const styles = useStyles();
    const { t } = useTranslation(['common', 'product', 'catalog']);
    const {
        onClose, selectedValue, keyProduct, open, data, weltpixel_labels, storeConfig = {},
    } = props;
    let productKey;
    for (let i = 0; i < data.items.length; i += 1) {
        if (keyProduct === data.items[i].url_key) {
            productKey = [i];
        }
    }

    const product = data?.items[productKey];
    const reviewValue = parseInt(product?.review?.rating_summary, 0) / 20;
    const isAsicsSpecial = product?.is_asics_special === true ? true : false;
    const pdpNotification = product?.pdp_notification || '';

    let enableMultiSeller = false;
    if (storeConfig) {
        enableMultiSeller = storeConfig.enable_oms_multiseller === '1';
    }

    // getSeller gql
    const [actGetSeller, { data: dSeller }] = getSeller();

    // get countdown cms block
    const [getCountDown, { data: dataCmsCountdown, loading: loadingCmsCountdown }] = getCmsBlocksRaw();

    const [getRaffleCms, { data: dataCmsRaffle, loading: loadingCmsRaffle }] = getCmsBlocksLazy();

    React.useEffect(() => {
        if (product && customCmsBlock) {
            actGetSeller({
                variables: {
                    input: {
                        seller_id: [parseFloat(product.seller_id)],
                    },
                },
            });
            const { countdown, tncRaffle } = customCmsBlock;
            getCountDown({
                variables: {
                    identifiers: [countdown],
                },
            });
            getRaffleCms({
                variables: {
                    identifiers: [tncRaffle],
                },
            });
        }
    }, [product, customCmsBlock]);

    const countDownData = dataCmsCountdown?.cmsBlocksRaw?.items?.length > 0 ? dataCmsCountdown.cmsBlocksRaw.items[0].content : null;
    let elementCountdown;
    let isCountDown = false;
    if (countDownData) {
        const mixedContents = countDownData.replace('[/mgz_pagebuilder]', '[mgz_pagebuilder]').split('[mgz_pagebuilder]');
        const removeIdentifier = JSON.parse(mixedContents[1]);
        elementCountdown = removeIdentifier?.elements[0]?.elements[0]?.elements[0];
        isCountDown = elementCountdown.type === 'countdown';
    }
    const isCommingSoon = product?.coming_soon === 1;

    let dataSeller;
    let citySplit;
    if (enableMultiSeller && dSeller && dSeller.getSeller) {
        dataSeller = dSeller && dSeller.getSeller;
    }
    if (enableMultiSeller && dataSeller && dataSeller.length > 0) {
        citySplit = dataSeller[0].city?.split(',');
    }

    // generate banner image
    const bannerData = [];
    if (product?.media_gallery?.length > 0) {
        // eslint-disable-next-line array-callback-return
        product.media_gallery.map((media) => {
            bannerData.push({
                link: '#',
                imageUrl: media.url,
            });
        });
    } else {
        bannerData.push({
            link: '#',
            imageUrl: product?.image?.url,
        });
    }

    const [banner, setBanner] = React.useState(bannerData);
    const [price, setPrice] = React.useState({
        priceRange: product.price_range,
        priceTiers: product.price_tiers,
        // eslint-disable-next-line no-underscore-dangle
        productType: product.__typename,
        specialFromDate: product.special_from_date,
        specialToDate: product.special_to_date,
    });
    const [additionalPrice, setAdditionalPrice] = React.useState(0);
    const [stockStatus, setStockStatus] = React.useState(product.stock_status);

    const handleClose = () => {
        onClose(selectedValue);
    };

    const combinedProps = {
        ...props,
        setStockStatus,
        setBanner,
        setPrice,
        price,
        setAdditionalPrice,
        loadingCmsCountdown,
        loadingCmsRaffle,
        dataCmsRaffle,
    };

    return (
        <Dialog scroll="body" onClose={handleClose} fullWidth maxWidth="md" open={open}>
            <div className={styles.modal}>
                <IconButton className={styles.btnClose} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <div className={classNames(styles.container, 'row')}>
                    <div className={classNames(styles.headContainer, 'col-xs-12 col-lg-6')}>
                        <Banner
                            data={banner}
                            noLink
                            thumbnail={false}
                            showArrow
                            autoPlay={false}
                            width={600}
                            height={600}
                            customClassCaraousel={styles.caraousel}
                            storeConfig={storeConfig}
                        >
                            {storeConfig?.pwa?.label_enable && storeConfig?.pwa?.label_weltpixel_enable && (
                                <WeltpixelLabel t={t} weltpixel_labels={weltpixel_labels} categoryLabel={false} />
                            )}
                        </Banner>
                    </div>
                    <div className={classNames(styles.body, 'col-xs-12 col-lg-6')}>
                        {isAsicsSpecial && (
                            <div className="limited-product-info">
                                <Typography
                                    variant="title"
                                    type="bold"
                                    color="red"
                                    className={classNames(styles.titleLimited, 'clear-margin-padding')}
                                >
                                    {t('product:infoLimitedProduct')}
                                </Typography>
                            </div>
                        )}
                        {pdpNotification && (
                            <div className={styles.pdpNotification} dangerouslySetInnerHTML={{ __html: pdpNotification }} />
                        )}
                        <div className={styles.titleContainer}>
                            <div className={styles.titlePriceContainer}>
                                <Typography
                                    variant="title"
                                    type="bold"
                                    letter="capitalize"
                                    className={classNames(styles.title, 'clear-margin-padding')}
                                >
                                    {product.name}
                                </Typography>
                                <PriceFormat {...price} additionalPrice={additionalPrice} />
                            </div>
                        </div>
                        <div className={styles.titleContainer}>
                            <div className={classNames('row', styles.sku)}>
                                <Typography className="clear-margin-padding" variant="p" type="regular" letter="capitalize">
                                    SKU#:
                                    {' '}
                                </Typography>
                                <Typography variant="p" type="bold" letter="none">
                                    {product.sku || ''}
                                </Typography>
                            </div>
                            <Typography variant="p" type="bold" letter="uppercase">
                                {stockStatus.replace(/_/g, ' ') || ''}
                            </Typography>
                        </div>

                        <div className={styles.titleContainer}>
                            <div className={styles.ratingContainer}>
                                <RatingStar value={reviewValue || 0} />
                                <Typography variant="p" type="regular" letter="capitalize">
                                    {product.review.reviews_count || 0}
                                    {' '}
                                    {t('product:review')}
                                </Typography>
                            </div>
                        </div>
                        {enableMultiSeller && dataSeller && dataSeller.length > 0 ? (
                            <div className={styles.titleContainer}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Link href={`/seller/${dataSeller[0].id}`}>
                                            <Avatar alt="Remy Sharp" src={dataSeller[0].logo} className={styles.imageContainer} variant="rounded" />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Link href={`/seller/${dataSeller[0].id}`}>
                                            <Box>
                                                <Typography variant="p" type="bold" letter="capitalize" size="14">
                                                    {dataSeller[0].name}
                                                </Typography>
                                                <Typography variant="p" type="regular" letter="capitalize" size="14">
                                                    {citySplit[0]}
                                                </Typography>
                                            </Box>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : null}
                        <div className="row">
                            {storeConfig?.pwa?.label_enable && storeConfig?.pwa?.label_weltpixel_enable && (
                                <WeltpixelLabel t={t} weltpixel_labels={weltpixel_labels || []} categoryLabel={false} onDetailProduct />
                            )}
                        </div>

                        <div className="hidden-desktop">
                            {' '}
                            <div className={styles.desc}>
                                <Typography variant="span" type="regular" size="10">
                                    {product.short_description.html ? (
                                        <span dangerouslySetInnerHTML={{ __html: product.short_description.html }} />
                                    ) : null}
                                </Typography>
                            </div>
                        </div>

                        {/* <ProductWrapperAction
                            {...props}
                            setStockStatus={setStockStatus}
                            setBanner={setBanner}
                            setPrice={setPrice}
                            price={price}
                            setAdditionalPrice={setAdditionalPrice}
                        /> */}

                        {countDownData && isCountDown && isCommingSoon ? (
                            <CountDown content={elementCountdown} completeProps={combinedProps} CompleteContent={ProductWrapperAction} />
                        ) : <ProductWrapperAction {...combinedProps} />}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

QuickView.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default QuickView;
