import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, PRIMARY, WHITE, GRAY_SECONDARY, ASICS_MAIN, GREEN, ASICS_MAIN_BG_SHADE, ASICS_MAIN_BG, SECONDARY,
} from '@theme_color';
import {
    CenterAbsolute, CreateBorder, CreateMargin, CreatePadding, FlexColumn, FlexRow, Centering,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingTop: 25,
    },
    btnClose: {
        position: 'absolute',
        top: 0,
        right: 20,
        zIndex: 99,
    },
    container: {
        margin: '0 !important',
        padding: '0px 20px 0px 20px',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            ...FlexColumn,
        },
        position: 'relative',
        '& .customizable-container': {
            marginTop: 20,
        },
    },
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    headContainer: {
        position: 'relative',
        backgroundColor: WHITE,
        width: '100%',
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3,
    },

    body: {
        ...CreatePadding(15, 30, 30, 30),
        ...FlexColumn,
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        '& .tnc-cms': {
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .tnc-cms ol': {
            paddingLeft: 15,
        },
        '& .title-top': {
            marginBottom: '25px',
            alignItems: 'flex-start',
            '& > div': {
                '& > h1': {
                    marginBottom: '15px !important',
                },
                '& .price_text': {
                    padding: 0,
                    lineHeight: 'normal',
                    textAlign: 'left',
                },
            },
            '& .add-to-wishlist': {
                marginTop: '-10px',
                '& .MuiSvgIcon-root': {
                    fontSize: 26,
                },
            },
        },
        '& .title-sku-stock': {
            marginBottom: 15,
            '& .sku': {
                '& p': {
                    fontSize: '14px',
                    fontWeight: '400',
                    margin: 0,
                    '&:last-child': {
                        marginLeft: 5,
                    },
                },
            },
            '& .stock': {
                fontSize: '14px',
                color: GREEN,
            },
        },
        '& .review': {
            '& .MuiIconButton-root': {
                color: `${ASICS_MAIN_BG_SHADE} !important`,
                '& .MuiSvgIcon-root': {
                    width: '1.2rem',
                    height: '1.2rem',
                },
                '& svg': {
                    color: `${ASICS_MAIN_BG_SHADE} !important`,
                },
            },
            '& .MuiIconButton-root.Mui-disabled': {
                color: `${ASICS_MAIN_BG} !important`,
                '& svg': {
                    color: `${ASICS_MAIN_BG} !important`,
                },
            },
            '& .text-review': {
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 10,
                fontSize: 13,
            },
        },
        '& div:not(.wrapperSize)': {
            '& >.sizing-information': {
                display: 'none',
            },
        },
        '& .wrapperColor~.wrapperColor, .wrapperWarna~.wrapperColor': {
            display: 'none',
        },
        '& .wrapperColor, .wrapperWarna': {
            position: 'relative',
            marginBottom: 20,
            '& .labelColor, .labelWarna': {
                fontSize: 16,
                fontWeight: '700',
                marginRight: 10,
                marginBottom: 13,
                display: 'block',
                color: ASICS_MAIN,
            },
            '& .product-configurableOption-Color, .product-configurableOption-Warna': {
                '& > div:not(.radio-container)': {
                    position: 'absolute',
                    top: 0,
                    left: 50,
                    '& >span': {
                        fontSize: 16,
                    },
                },
                '& .radio-container': {
                    justifyContent: 'flex-start',
                    margin: '0 -5px',
                    '& > div': {
                        border: '1px solid rgba(0,0,0,0.1) !important',
                        margin: '5px !important',
                        '&.selected': {
                            border: `3px solid ${SECONDARY} !important`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    },
                },
            },
        },
        '& .wrapperSize, .wrapperUkuran': {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: 20,
            '& .labelSize, .labelUkuran': {
                fontSize: 16,
                fontWeight: '700',
                marginRight: 10,
                marginBottom: 8,
                color: ASICS_MAIN,
            },
            '& >.sizing-information': {
                fontSize: 14,
                marginBottom: 8,
                display: 'flex',
                alignItems: 'center',
                '& >img': {
                    width: 18,
                    height: 18,
                    marginRight: 8,
                },
                '& >span': {
                    color: ASICS_MAIN,
                },
            },
            '& .product-configurableOption-Size, .product-configurableOption-Ukuran': {
                width: '100%',
                marginBottom: 0,
                '& > div:not(.radio-container)': {
                    position: 'absolute',
                    top: 0,
                    left: 75,
                    '& >span': {
                        fontSize: 16,
                    },
                },
                '& .radio-container': {
                    justifyContent: 'flex-start',
                    margin: '0 -14px',
                    '& > div': {
                        height: '40px !important',
                        width: '40px !important',
                        margin: '14px !important',
                        backgroundColor: ASICS_MAIN_BG,
                        border: '0 transparent !important',
                        '& >span': {
                            color: '#FFFFFF',
                            fontWeight: '400',
                            fontSize: 14,
                        },
                        '&.selected': {
                            backgroundColor: ASICS_MAIN_BG_SHADE,
                        },
                    },
                },
            },
        },
        '& .product-OptionItem-qty': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& > span': {
                fontSize: 16,
                margin: 0,
                textTransform: 'uppercase',
                marginRight: 36,
            },
            '& > div': {
                height: '34px',
                borderRadius: '17px',
                border: `1px solid ${ASICS_MAIN}`,
                overflow: 'hidden',
                width: '104px',
                '& > div': {
                    background: 'transparent',
                    border: '0 transparent !important',
                    fontSize: '20px',
                    display: 'block',
                    textAlign: 'center',
                    height: '32px',
                },
                '& input': {
                    fontSize: '15px',
                    border: '0 transparent',
                    minWidth: '45px',
                    backgroundColor: 'transparent',
                },
            },
        },
        '& #plugin-addToCart-btn': {
            width: '209px',
            height: '41px',
            '& .MuiTypography-root': {
                fontSize: '14px',
                textTransform: 'capitalize',
            },
        },
        '& .wrapper-share': {
            paddingTop: '34px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            '& > h1': {
                fontSize: '16px',
                fontWeight: '700',
                margin: '0',
                paddingRight: '20px',
                color: ASICS_MAIN,
            },
        },
    },

    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-arround',
        position: 'fixed',
        bottom: 0,
        left: 0,
        ...CenterAbsolute,
        background: 'rgba(255,255,255,0.7)',
        // opacity : 0.7,
        ...CreatePadding(20, 20, 20, 20),
        zIndex: theme.zIndex.drawer + 1,
    },

    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 30,
        },
    },

    titleLimited: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },

    pdpNotification: {
        '& p': {
            margin: 0,
            fontSize: 16,
        },
        marginBottom: 5,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 10,
            fontSize: 22,
            '& p': {
                margin: 0,
                fontSize: 22,
            },
        },
    },

    btnGoToProduct: {
        marginTop: 20,
        width: '50%',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
    },

    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },

    titleContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    titlePriceContainer: {
        ...FlexColumn,
        flex: 1,
        fontSize: 20,
        '& .price_text': {
            fontSize: 30,
            textAlign: 'left',
            padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
            '& .price_text': {
                fontSize: 18,
            },
        },
    },
    desktopShareIcon: {
        paddingTop: '34px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& > h1': {
            fontSize: '16px',
            fontWeight: '700',
            margin: '0',
            paddingRight: '20px',
            color: ASICS_MAIN,
        },
        '& .content-share': {
            width: 'calc(100% - 73px)',
            alignItems: 'center',
            display: 'block',
            '& > button': {
                lineHeight: '0 !important',
                margin: '3px 16px 3px 0',
                height: '28px',
                width: '28px',
                borderRadius: '50%',
                border: `1px solid ${ASICS_MAIN} !important`,
            },
            '& i': {
                color: ASICS_MAIN,
            },
            '& > div': {
                width: '120px',
                float: 'right',
            },
            '& > div > div': {
                display: 'flex',
                justifyContent: 'flex-end',
            },

        },
    },

    shareContainer: {
        ...FlexRow,
        justifyContent: 'flex-end',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            '& button span': {
                fontSize: 9,
            },
        },
    },

    shareRootContainer: {
        ...CreatePadding(15, 30, 30, 30),
        ...FlexColumn,
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    btnShare: {
        margin: '0px !important',
    },

    iconShare: {
        color: PRIMARY,
        fontSize: 25,
        ...CreateMargin(0, 0, 0, 0),
    },

    carouselContainer: {
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 70,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    carouselTitle: {
        marginBottom: '20px',
    },
    desc: {
        ...CreateMargin(12, 0, 12, 0),
        textAlign: 'center',
    },
    ratingContainer: {
        ...FlexRow,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
        },
    },
    btnLoadReview: {
        ...Centering,
        textAlign: 'center',
    },
    textLoadReview: {
        color: `${GRAY_SECONDARY} !important`,
    },
    sku: {
        alignItems: 'center',
        marginLeft: '0 !important',
    },
    tabs: {
        paddingTop: '40px',
    },
    shareTitle: {
        marginTop: 20,
        fontSize: 12,
    },
}));

export default useStyles;
