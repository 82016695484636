/* eslint-disable prefer-template */
const BLOCK_CLASS_MGZ_PREFIX = 'mgz';
const BLOCK_CLASS_COL_PREFIX = 'col';
export const BLOCK_CLASS_COL_SIZE_PREFIX = BLOCK_CLASS_MGZ_PREFIX + '-' + BLOCK_CLASS_COL_PREFIX;
export const BLOCK_CLASS_CONTAINER = 'mgz-container';

const generateNewBlockColumnClass = (classNameString) => {
    const classNameList = classNameString.split(' ');
    const colSizeClassNames = classNameList
        .filter((className) => className.includes(BLOCK_CLASS_COL_SIZE_PREFIX));
    const newColSizeClassNames = colSizeClassNames
        .map((className) => className.replace(`${BLOCK_CLASS_MGZ_PREFIX}-`, ''));
    const newColSizeClassNameString = newColSizeClassNames.join(' ').toString();
    return newColSizeClassNameString;
};

export default generateNewBlockColumnClass;
