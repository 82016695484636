import { withTranslation } from '@i18n';
import { withApollo } from '@lib_apollo';
import Core from '@core_modules/productfinder/pages/default/core';
import Content from '@core_modules/productfinder/pages/default/components';

const Page = (props) => {
    const { data } = props;
    const pageConfig = {
        title: data.cmsPage.title,
    };

    return <Core pageConfig={pageConfig} Content={Content} {...props} />;
};

export default withApollo({ ssr: false })(withTranslation()(Page));
