/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ImageSlide from '@common_slick/Banner/ImageSlider';
import useStyles from '@common_slick/Banner/style';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIos';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import classNames from 'classnames';
import React, { useState } from 'react';
import Slider from 'react-slick';

const Banner = ({
    data = [],
    height,
    width,
    contentWidth = '',
    autoPlay = true,
    noLink = false,
    showArrow = true,
    speed = 500,
    autoplaySpeed = 4000,
    storeConfig = {},
}) => {
    const styles = useStyles();
    const [slideIndex, setIndex] = useState(0);
    const [count, setCount] = useState(0);
    let sliderRef = React.createRef();

    const isSliderContDown = Array.isArray(data) && data.length > 0 && data[0].sliderType === 'pwa-slider-countdown';

    /* eslint-disable no-unused-vars */
    const dotActive = data.length > 1 ? classNames('dotsItem', 'dotActive') : styles.hide;
    const dotItem = data.length > 1 ? 'dotsItem' : styles.hide;
    const handleLeftArrow = () => {
        if (slideIndex === 0) {
            sliderRef.slickPrev(data.length - 1);
        } else {
            sliderRef.slickPrev(slideIndex - 1);
        }
    };

    const handleRightArrow = () => {
        if (slideIndex === data.length - 1) {
            sliderRef.slickNext(0);
        } else {
            sliderRef.slickNext(slideIndex + 1);
        }
    };

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoPlay,
        speed,
        autoplaySpeed,
        rtl: false,
        arrows: false,
        afterChange: () => setCount(count + 1),
        beforeChange: (current, next) => setIndex(next),
    };

    return (
        <div className={styles.caraousel}>
            {data && data.length > 0 && (
                <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                    {data.map((item, key) => (
                        <ImageSlide
                            storeConfig={storeConfig}
                            height={height}
                            width={width}
                            noLink={noLink}
                            contentWidth={contentWidth}
                            key={key}
                            preload={key === 0}
                            {...item}
                        />
                    ))}
                </Slider>
            )}
            {showArrow && !isSliderContDown ? (
                <>
                    <div className={classNames(styles.arrow, styles.leftArrow, 'arrow arrow-left')} onClick={handleLeftArrow}>
                        <LeftArrowIcon fontSize="inherit" />
                    </div>
                    <div className={classNames(styles.arrow, styles.rightArrow, 'arrow arrow-right')} onClick={handleRightArrow}>
                        <RightArrowIcon fontSize="inherit" />
                    </div>
                </>
            ) : null}
            <div className={classNames(styles.dots, 'dots')}>
                {data.map((item, id) => (
                    /* eslint-disable jsx-a11y/click-events-have-key-events */
                    /* eslint-disable jsx-a11y/no-static-element-interactions */
                    <div className={slideIndex === id ? 'dotItem dotActive' : 'dotItem'} key={id} onClick={() => sliderRef.slickGoTo(id)} />
                    // <div
                    // className={classNames(styles.slideIndex, id ? 'item dotActive' : 'dotItem item')}
                    // key={id}
                    // onClick={() => sliderRef.slickGoTo(id)} />
                ))}
            </div>
        </div>
    );
};

export default Banner;
