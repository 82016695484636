import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_LIGHT, GRAY_SECONDARY, ASICS_MAIN, WHITE,
} from '@theme_color';

const useStyles = makeStyles((theme) => ({
    // Template
    templateBlockColumn: {
        width: '100%',
        maxWidth: 500,
        padding: '0 15px',
        '& .options-container': {
            '& .option': {
                cursor: 'pointer',
                border: `solid 1px ${GRAY_SECONDARY}`,
                borderRadius: 6,
                padding: '4px 20px',
                marginBottom: 14,
                [theme.breakpoints.down('375')]: {
                    padding: '0px 8px',
                },
                display: 'flex',
                '& .option-checkbox': {
                    marginTop: -3,
                    '&:hover': {
                        background: 'none',
                    },
                },
                '& .option-img': {
                    display: 'none',
                },
                '& .option-description': {
                    color: ASICS_MAIN,
                    fontSize: 15,
                    fontWeight: 'bold',
                },
            },
            '& .option.selected': {
                border: `solid 1px ${ASICS_MAIN}`,
            },
        },
    },
    templateBlockRow: {
        width: '100%',
        maxWidth: 900,
        [theme.breakpoints.down('md')]: {
            padding: '0 15px',
        },
        '& .options-container': {
            marginTop: 24,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '20px',
            '& .option': {
                width: 270,
                minWidth: 150,
                height: '100%',
                padding: 12,
                cursor: 'pointer',
                border: `solid 2px ${WHITE}`,
                '& .option-img': {
                    width: '100% !important',
                    margin: 'auto',
                },
                '& .option-multiselect-container': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .option-checkbox': {
                        padding: 0,
                        marginLeft: -2,
                        marginRight: 4,
                        marginTop: -5,
                    },
                    '& .option-description': {
                        margin: 0,
                    },
                },
                '& .option-description': {
                    color: ASICS_MAIN,
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginBottom: 6,
                },
                '& .option-note': {
                    marginTop: 0,
                    color: ASICS_MAIN,
                },
            },
            '& .option.selected': {
                border: `solid 2px ${ASICS_MAIN}`,
            },
        },
    },
    templateChip: {
        width: '100%',
        maxWidth: 600,
        padding: '0 15px',
        '& .options-container': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
            '& .option': {
                cursor: 'pointer',
                color: ASICS_MAIN,
                fontWeight: 'bold',
                textAlign: 'center',
                border: 'solid 2px #B6B8DC',
            },
            '& .option.selected': {
                border: `solid 2px ${ASICS_MAIN}`,
            },
        },
        '& .col-2': {
            '& .option': {
                flex: 1,
                '&:first-child': {
                    borderRight: 'solid 1.5px #B6B8DC',
                    borderRadius: '40px 0 0 40px',
                },
                '&:nth-child(2)': {
                    borderLeft: 'solid 1.5px #B6B8DC',
                    borderRadius: '0 40px 40px 0',
                },
                '& .option-img': {
                    display: 'none',
                },
                [theme.breakpoints.down('400')]: {
                    '& .option-description': {
                        margin: '.8em',
                    },
                },
            },
        },
        '& .col-3': {
            gap: '10px',
            '& .option': {
                flex: 1,
                borderRadius: 40,
                [theme.breakpoints.down('400')]: {
                    '& .option-description': {
                        margin: '.65em',
                    },
                },
            },
        },
        '& .col-multiple': {
            marginTop: 24,
            display: 'grid',
            width: '100%',
            gridGap: '12px 8px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(80px, auto))',
            '& .option': {
                minWidth: 80,
                widht: 'fit-content',
                borderRadius: 40,
                padding: '9px 24px',
                '& .option-description': {
                    margin: 0,
                },
            },
        },
    },
    templateSquare: {
        width: '100%',
        maxWidth: 500,
        padding: '0 15px',
        '& .options-container': {
            width: 330,
            margin: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
            '& .option': {
                width: '48%',
                margin: '2px',
                padding: 4,
                border: `solid 2px ${WHITE}`,
                cursor: 'pointer',
                '& .option-img': {
                    margin: 'auto',
                    width: '100%',
                },
                '& .option-description': {
                    margin: '4px 0 0 0',
                    textAlign: 'center',
                    color: ASICS_MAIN,
                    fontWeight: 'bold',
                    lineHeight: 1,
                },
                '& .option-note': {
                    margin: 0,
                    textAlign: 'center',
                    color: ASICS_MAIN,
                },
            },
            '& .option.selected': {
                border: `solid 2px ${ASICS_MAIN}`,
            },
        },
    },

    // Reusable styling
    title: {
        fontSize: 24,
        color: ASICS_MAIN,
        textAlign: 'center',
        marginTop: 0,
        marginBottom: 6,
    },
    description: {
        maxWidth: '100% !important',
        marginBottom: 20,
        '& p,span': {
            textAlign: 'center',
            color: ASICS_MAIN,
            marginBottom: 0,
            marginTop: 0,
        },
        '& img': {
            marginTop: 12,
            maxWidth: '100% !important',
        },
    },
    imgBlockCol: {
        margin: 'auto',
        marginBottom: 24,
        width: 325,
        height: 175,
        background: GRAY_LIGHT,
    },
    continueButton: {
        marginTop: '20px !important',
        width: '100% !important',
        maxWidth: '300px !important',
        padding: '11px 0 !important',
        textTransform: 'none !important',
        fontWeight: '600 !important',
        '&:disabled': {
            color: WHITE,
        },
    },
    skipButton: {
        marginTop: '4px !important',
        width: '100% !important',
        maxWidth: '300px !important',
        padding: '10px 0 !important',
        fontWeight: 'bold !important',
        textTransform: 'none !important',
        '&:hover, &:active': {
            background: 'none !important',
        },
    },
    anotherInfo: {
        color: ASICS_MAIN,
        margin: '32px auto auto auto',
        textAlign: 'center',
        maxWidth: 500,
    },
}));

export default useStyles;
