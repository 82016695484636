export const MAX_WIDTH = '1440px';
export const BREAKPOINTS = {
    xs: 0,
    ms: 425,
    sm: 768,
    md: 1024,
    lg: 1200,
    lgs: 1250,
    xl: 1920,
};
export default {};
