/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import Header from '@core_modules/productfinder/pages/default/components/Header';
import Result from '@core_modules/productfinder/pages/default/components/Result';
import Quiz from '@core_modules/productfinder/pages/default/components/Quiz';
import Intro from '@core_modules/productfinder/pages/default/components/Intro';
import useStyles from '@core_modules/productfinder/pages/default/components/styles';
import Loader from '@common_loaders';

const Content = (props) => {
    const {
        t, slug, handleBack, handleNext, handleSkipPreQuiz, currentSlideIndex,
        sliderItems, quizGroup, loading, isShowResult, handleAnswer, cmsPageData, cmsBlocksData,
    } = props;
    const styles = useStyles();
    const quizItems = !loading && quizGroup && quizGroup.quiz ? quizGroup?.quiz : [];
    const quizGroupInactive = quizGroup && !quizGroup.status;
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        // on first page (intro), percentage should be at 0%
        if (!loading && currentSlideIndex < sliderItems.length) {
            const numberOfSlides = sliderItems.length - 1;
            setPercentage(Math.round((currentSlideIndex / numberOfSlides) * 100));
        }
        window.scrollTo(0, 0);
    }, [currentSlideIndex]);

    const renderSliderItem = (sliderItem, sliderIndex) => {
        if (sliderItem.type === 'introduction') {
            return <Intro t={t} sliderIndex={sliderIndex} handleNext={handleNext} cmsData={cmsBlocksData} />;
        } if (sliderItem.type === 'quiz' || sliderItem.type === 'prequiz') {
            return (
                <Quiz
                    t={t}
                    sliderIndex={sliderIndex}
                    data={quizItems.find((quizItem) => quizItem.quiz_id === sliderItem.quiz_id)}
                    handleNext={handleNext}
                    handleSkipPreQuiz={handleSkipPreQuiz}
                    handleAnswer={handleAnswer}
                    isLastQuiz={currentSlideIndex === sliderItems.length - 1}
                />
            );
        }
    };

    if (loading) {
        return (
            <>
                <Header {...props} />
                <div className={styles.loadingContainer}>
                    <h1>{t('productfinder:loadingContent')}</h1>
                    <Loader size={32} />
                </div>
            </>
        );
    }
    if (quizGroupInactive || !quizGroup) {
        return (
            <>
                <Header {...props} />
                <div className={styles.sliderContainer}>
                    <h1 style={{ margin: 'auto', textTransform: 'capitalize' }}>
                        {`${slug[0].replace('/', '').replace('-', ' ')} ${t('productfinder:notFound')}`}
                    </h1>
                </div>
            </>
        );
    }

    return (
        <>
            <Header
                productFinderType={quizGroup.title}
                title={sliderItems.length > 0 ? sliderItems[currentSlideIndex]?.header : null}
                handleBack={handleBack}
                percentage={percentage}
                useBack={(currentSlideIndex !== 0 && !isShowResult)}
                cmsPageData={cmsPageData}
                {...props}
            />

            {!isShowResult ? (
                <div className={styles.sliderContainer}>
                    {sliderItems.map((item, sliderIndex) => (
                        <div
                            className={styles.sliderItem}
                            style={{ transform: `translate(-${currentSlideIndex * 100}%)`, transition: '.7s ease-out' }}
                        >
                            {renderSliderItem(item, sliderIndex)}
                        </div>
                    ))}
                </div>
            ) : <Result t={t} cmsData={cmsBlocksData} />}
        </>
    );
};

export default Content;
