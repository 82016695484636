import React from 'react';
import View from '@core_modules/cms/components/cms-renderer/magezon/MagezonStaticBlock/view';
import { getCmsBlocks, getCmsBlocksRaw } from '@core_modules/cms/services/graphql';
import Alert from '@material-ui/lab/Alert';
import Loading from '@common_loaders/Backdrop';
import { customCmsBlock } from '@config';
import CountDown from '@core_modules/product/pages/default/components/CountDown';

const CountDownView = (props) => {
    const { content } = props;
    const mixedContents = content.replace('[/mgz_pagebuilder]', '[mgz_pagebuilder]').split('[mgz_pagebuilder]');
    const removeIdentifier = JSON.parse(mixedContents[1]);
    const elementCountdown = removeIdentifier?.elements[0]?.elements[0]?.elements[0];
    const isCountDown = elementCountdown?.type === 'countdown';
    if (isCountDown) {
        return <CountDown content={elementCountdown} />;
    }

    return null;
};
const MagezonStaticBlock = (props) => {
    const { identifier, ...other } = props;
    const { t } = props;
    const { countdown } = customCmsBlock;
    const isCountDown = countdown === identifier;
    const { data, loading, error } = isCountDown ? getCmsBlocksRaw({ identifiers: [identifier] })
        : getCmsBlocks({ identifiers: [identifier] });
    if (loading) {
        return <Loading open={loading} />;
    }
    if (error) {
        return (
            <Alert severity="error">{t('common:error:fetchError')}</Alert>
        );
    }
    if (!isCountDown && data && data.cmsBlocks && data.cmsBlocks.items && data.cmsBlocks.items.length > 0) {
        return (
            <View
                {...other}
                content={data.cmsBlocks.items[0].content}
            />
        );
    }

    if (isCountDown && data && data.cmsBlocksRaw && data.cmsBlocksRaw.items && data.cmsBlocksRaw.items.length > 0) {
        return <CountDownView content={data.cmsBlocksRaw.items[0].content} />;
    }

    return null;
};

export default MagezonStaticBlock;
