/* eslint-disable consistent-return */
import Button from '@common_button';
import parse from 'html-react-parser';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE } from '@theme_color';

const useStyles = makeStyles(() => ({
    container: {
        width: '90%',
        maxWidth: 600,
        '& img': {
            maxWidth: '100%',
        },
    },
    alertContainer: {
        width: '100%',
        '& h1': {
            textAlign: 'center',
        },
    },
    button: {
        margin: '32px 0 16px 0 !important',
        width: '100% !important',
        maxWidth: '300px !important',
        padding: '11px 0 !important',
        textTransform: 'none !important',
        fontWeight: '600 !important',
        '&:disabled': {
            color: WHITE,
        },
    },
}));

const Introduction = (props) => {
    const {
        t, sliderIndex, handleNext, cmsData,
    } = props;
    const styles = useStyles();
    const content = cmsData?.cmsBlocks?.items[0].content;
    const options = {
        replace: ({ attribs, children }) => {
            if (attribs) {
                if (attribs.class && attribs.class.includes('mgz-btn')) {
                    return (
                        <Button
                            className={styles.button}
                            onClick={() => handleNext(sliderIndex)}
                        >
                            {children[0].children[0].data}
                        </Button>
                    );
                }
            }
        },
    };

    if (cmsData && cmsData.cmsBlocks && cmsData.cmsBlocks.items[0] && cmsData.cmsBlocks.items[0].content) {
        return <div className={styles.container}>{parse(content, options)}</div>;
    }

    return (
        <div className={styles.alertContainer}>
            <h1 style={{ marginTop: 0 }}>{`CMS Block ${t('productfinder:notFound')}`}</h1>
            <Button
                className={styles.button}
                onClick={() => handleNext(sliderIndex)}
            >
                {t('productfinder:continue')}
            </Button>
        </div>
    );
};

export default Introduction;
