/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
import React from 'react';
import useStyles from '@core_modules/cms/components/cms-renderer/magezon/style';
import parse from 'html-react-parser';
import generateNewBlockColumnClass, { BLOCK_CLASS_CONTAINER, BLOCK_CLASS_COL_SIZE_PREFIX } from '@core_modules/cms/helpers/blockClassName';

const ParsedBlockContent = ({ content }) => (
    parse(content, {
        replace: (domNode) => {
            // handle row layout on cms blocks
            if (domNode?.attribs?.class) {
                // handle row element
                if (domNode.attribs.class.includes(BLOCK_CLASS_CONTAINER)) {
                    domNode.attribs.class += ' row';
                }
                // handle column element
                if (domNode.attribs.class.includes(BLOCK_CLASS_COL_SIZE_PREFIX)) {
                    domNode.attribs.class += ' ' + generateNewBlockColumnClass(domNode.attribs.class);
                }
            }
        },
    })
);
const MagezonStaticBlockView = (props) => {
    const {
        xs_hide, sm_hide, button_size, md_hide, lg_hide,
        content, ...other
    } = props;
    const classes = useStyles(other);
    let className = 'cms-container ';
    if (xs_hide) className += 'hidden-mobile ';
    if (sm_hide) className += 'hidden-sm ';
    if (md_hide) className += 'hidden-md ';
    if (lg_hide) className += 'hidden-lg ';

    return (
        <div className={`${classes.container} ${className}`}>
            <ParsedBlockContent content={content} />
        </div>
    );
};

export default MagezonStaticBlockView;
