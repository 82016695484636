/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable radix */

import { useEffect, useState } from 'react';
import {
    getCmsBlocks, getQuizByGroupId, getQuizAnswered, setQuizAnswered,
} from '@core_modules/productfinder/services/graphql';
import { useRouter } from 'next/router';
import { setLocalStorage, getLocalStorage } from '@helper_localstorage';
import { getLoginInfo } from '@helper_auth';
import Layout from '@core_modules/theme/layout';

const ProductFinder = (props) => {
    const {
        t, slug, data: cmsPageData, Content, pageConfig, storeConfig,
    } = props;
    const router = useRouter();
    const isEditMode = router.query.edit === 'true';
    const isLogin = getLoginInfo();

    const [loading, setLoading] = useState(true);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isShowResult, setIsShowResult] = useState(false);
    const [quizGroup, setQuizGroup] = useState();
    const [sliderItems, setSliderItems] = useState([]);

    const [getCmsBlocksContent, { data: cmsBlocksData, loading: cmsBlocksLoading, error: cmsBlocksError }] = getCmsBlocks();
    const [getQuizData, { data: quizData, loading: quizDataLoading, error: quizDataError }] = getQuizByGroupId();
    const [getQuizAnsweredData, { data: customerAnsweredData, loading: customerAnsweredDataLoading }] = getQuizAnswered();
    const [setQuizCustomerAnswered] = setQuizAnswered();

    const getLocalAnsweredData = (groupId) => {
        const localAnsweredDataList = getLocalStorage('product_finder');
        if (localAnsweredDataList && localAnsweredDataList.length > 0) {
            return localAnsweredDataList.find((answeredItem) => answeredItem.group_id === groupId);
        }
        return null;
    };

    useEffect(() => {
        if (cmsPageData && cmsPageData.cmsPage && cmsPageData.cmsPage.content) {
            const cmsPageContent = cmsPageData.cmsPage.content;
            const groupIdRegex = /<group_id>(.*?)<\/group_id>/;
            const introIdentifierRegex = /<intro_page_identifier>(.*?)<\/intro_page_identifier>/;
            const resultIdentifierRegex = /<result_page_identifier>(.*?)<\/result_page_identifier>/;

            const groupId = groupIdRegex.test(cmsPageContent)
                ? cmsPageContent.match(groupIdRegex)[1].replace(/\s/g, '')
                : '';
            const introPageIdentifier = introIdentifierRegex.test(cmsPageContent)
                ? cmsPageContent.match(introIdentifierRegex)[1].replace(/\s/g, '')
                : '';
            const resultPageIdentifier = resultIdentifierRegex.test(cmsPageContent)
                ? cmsPageContent.match(resultIdentifierRegex)[1].replace(/\s/g, '')
                : '';

            getQuizData({
                variables: {
                    quizGroupId: parseInt(groupId),
                },
            });
            getCmsBlocksContent({
                variables: {
                    identifiers: [introPageIdentifier, resultPageIdentifier],
                },
            });
            if (isLogin && isEditMode) {
                getQuizAnsweredData({
                    variables: {
                        group_id: parseInt(groupId),
                    },
                });
            }
        }
    }, []);

    useEffect(() => {
        const quizDataReady = !quizDataLoading && (quizData || quizDataError);
        if (quizDataReady && !customerAnsweredDataLoading && !cmsBlocksLoading) {
            setLoading(false);
        }
    }, [quizDataLoading, customerAnsweredDataLoading, cmsBlocksLoading]);

    useEffect(() => {
        const generateFilter = (quizList) => {
            const filter = {};
            quizList.forEach((quiz) => {
                const selectedAnswer = quiz.answers.filter((answer) => answer.selected);
                selectedAnswer.forEach((answer) => {
                    if (filter[quiz.attribute]) {
                        filter[quiz.attribute] = [...filter[quiz.attribute], answer.value];
                    } else {
                        filter[quiz.attribute] = [answer.value];
                    }
                });
            });
            return filter;
        };
        const parseFilterToQueryString = (filterObject) => {
            let formattedFilter = '';
            for (const attribute in filterObject) {
                let formattedFilterValue = '';
                filterObject[attribute].forEach((value, index) => {
                    formattedFilterValue += index === filterObject[attribute].length - 1 ? `${value}` : `${value},`;
                });
                formattedFilter += `${attribute}=${filterObject[attribute]}&`;
            }
            return formattedFilter.slice(0, -1);
        };

        if (isShowResult) {
            const quizOnly = quizGroup.quiz.filter((page) => page.answers && page?.answers.length > 0);
            const formattedQuiz = quizOnly.map((quiz) => {
                const formattedAnswers = quiz.answers
                    .filter((answer) => answer.selected)
                    .map((answer) => ({
                        description: answer.description,
                        value: answer.value,
                    }));

                return {
                    quiz_id: quiz.quiz_id,
                    question: quiz.title,
                    answer: formattedAnswers,
                };
            });

            const answeredData = {
                group_id: quizGroup.group_id,
                quiz_answer: formattedQuiz,
                sku_list: [],
            };

            const localAnsweredDataList = getLocalStorage('product_finder') || [];
            const localAnsweredData = getLocalAnsweredData(quizGroup.group_id);
            if (localAnsweredData) {
                const newLocalAnsweredDataList = localAnsweredDataList;
                const localAnsweredDataIndex = localAnsweredDataList
                    .findIndex((answeredData) => answeredData.group_id === localAnsweredData.group_id);
                newLocalAnsweredDataList[localAnsweredDataIndex] = answeredData;
                setLocalStorage('product_finder', newLocalAnsweredDataList);
            } else {
                setLocalStorage('product_finder', [...localAnsweredDataList, answeredData]);
            }
            if (isLogin) {
                setQuizCustomerAnswered({
                    variables: {
                        ...answeredData,
                    },
                });
            }

            const filter = generateFilter(quizGroup.quiz);
            router.push(`/productfinder/result/${slug[0]}?${parseFilterToQueryString(filter)}`);
        }
    }, [isShowResult]);

    useEffect(() => {
        const formatQuizList = (quizList, answeredData) => {
            let formattedQuizList;
            if (answeredData) {
                formattedQuizList = quizList.map((quiz) => {
                    const typeisQuiz = quiz.answers.length > 0;
                    if (typeisQuiz) {
                        const formattedAnswers = quiz.answers.map((answer) => {
                            const relatedQuiz = answeredData.quiz_answer.find((answeredQuiz) => quiz.quiz_id === answeredQuiz.quiz_id);
                            if (relatedQuiz) {
                                const isSelected = relatedQuiz.answer.some((itemAnswer) => itemAnswer.value === answer.value);
                                return { ...answer, selected: isSelected };
                            }
                            return { ...answer, selected: false };
                        });
                        return { ...quiz, answers: formattedAnswers };
                    }
                    return quiz;
                });
            } else {
                formattedQuizList = quizList.map((quiz) => {
                    const typeisQuiz = quiz.answers.length > 0;
                    if (typeisQuiz) {
                        const formattedAnswers = quiz.answers.map((answer) => ({ ...answer, selected: false }));
                        return { ...quiz, answers: formattedAnswers };
                    }
                    return quiz;
                });
            }
            return formattedQuizList;
        };

        const quizDataReady = quizData && quizData;
        const customerAnsweredReady = customerAnsweredData;

        if (!cmsBlocksLoading && quizDataReady) {
            const quizGroup = quizData.getQuizByGroupId[0];
            let answeredData = getLocalAnsweredData(quizGroup.group_id);
            if (isLogin && customerAnsweredReady) {
                answeredData = customerAnsweredData.getQuizCustomerAnswered;
            }

            const activeQuizList = quizGroup.quiz
                .filter((quiz) => quiz.status === true)
                .sort((a, b) => a.position - b.position || a.quiz_id - b.quiz_id);
            const formattedActiveQuizList = isEditMode && answeredData
                ? formatQuizList(activeQuizList, answeredData)
                : formatQuizList(activeQuizList);
            const quizItems = activeQuizList.map((activeQuiz) => {
                const typeisQuiz = activeQuiz.answers.length > 0;
                return {
                    type: typeisQuiz ? 'quiz' : 'prequiz',
                    quiz_id: activeQuiz.quiz_id,
                    header: activeQuiz.header,
                };
            });
            setQuizGroup({
                ...quizGroup,
                quiz: [
                    ...formattedActiveQuizList,
                ],
            });
            setSliderItems([
                { type: 'introduction' },
                ...quizItems,
            ]);
            setLoading(false);
        }
    }, [cmsBlocksLoading, quizData, customerAnsweredData]);

    const handleAnswer = (quiz_id, value) => {
        let updatedAnswers;
        const quizTemp = quizGroup.quiz;
        const indexRelatedQuiz = quizGroup.quiz.findIndex((quiz) => quiz.quiz_id === quiz_id);
        let relatedQuiz = quizGroup.quiz[indexRelatedQuiz];

        if (relatedQuiz.type === 'multiselect') {
            updatedAnswers = relatedQuiz.answers.map((answer) => (answer.value === value ? { ...answer, selected: !answer.selected } : answer));
            relatedQuiz = {
                ...relatedQuiz,
                answers: updatedAnswers,
            };
        } else {
            updatedAnswers = relatedQuiz.answers.map((answer) => (answer.value === value ? { ...answer, selected: !answer.selected } : { ...answer, selected: false }));
            relatedQuiz = {
                ...relatedQuiz,
                answers: updatedAnswers,
            };
        }

        quizTemp[indexRelatedQuiz] = relatedQuiz;
        setQuizGroup({
            ...quizGroup,
            quiz: quizTemp,
        });
    };

    const handleNext = (slideIndex) => {
        if (slideIndex < sliderItems.length - 1) {
            setCurrentSlideIndex(slideIndex + 1);
        } else if (slideIndex === (sliderItems.length - 1)) {
            setIsShowResult(true);
        }
    };

    const handleSkipPreQuiz = () => {
        let nextPageIndex;
        let isSkipToResult = false;
        for (let pageIndex = currentSlideIndex + 1; pageIndex < quizGroup.quiz.length; pageIndex++) {
            const isLastPage = pageIndex === quizGroup.quiz.length - 1;
            const isSameHeader = quizGroup.quiz[pageIndex].header === quizGroup.quiz[currentSlideIndex].header;
            const pageIsQuiz = quizGroup.quiz[pageIndex]?.answers.length > 0;

            if (pageIsQuiz) {
                if (!isSameHeader) {
                    nextPageIndex = pageIndex;
                    break;
                } else if (isSameHeader && isLastPage) {
                    isSkipToResult = true;
                    break;
                }
            } else {
                nextPageIndex = pageIndex;
                break;
            }
        }

        if (currentSlideIndex === quizGroup.quiz.length - 1 || isSkipToResult) {
            setIsShowResult(true);
        } else {
            setCurrentSlideIndex(nextPageIndex);
        }
    };

    const handleBack = () => {
        if (currentSlideIndex > 0) {
            setCurrentSlideIndex(currentSlideIndex - 1);
        }
    };

    const contentProps = {
        t,
        slug,
        storeConfig,
        loading,
        isShowResult,
        quizGroup,
        sliderItems,
        currentSlideIndex,
        cmsPageData,
        cmsBlocksData,
        handleBack,
        handleNext,
        handleSkipPreQuiz,
        setIsShowResult,
        handleAnswer,
    };

    return (
        <Layout pageConfig={pageConfig} {...props}>
            <Content {...props} {...contentProps} />
        </Layout>
    );
};
export default ProductFinder;
