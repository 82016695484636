/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import BackIcon from '@material-ui/icons/ArrowBackIos';
import useStyles from '@core_modules/productfinder/pages/default/components/Header/style';

const Header = (props) => {
    const {
        t, productFinderType, storeConfig, title, useBack, handleBack, percentage,
    } = props;
    const styles = useStyles();

    const defaultTitle = (
        <div className={styles.defaultTitle}>
            <img
                className="logo"
                src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                alt="logo"
            />
            { productFinderType && (
                <>
                    <span className="divider">|</span>
                    <span>{productFinderType.toUpperCase()}</span>
                </>
            )}
        </div>
    );

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.headerInnerContainer}>
                    { useBack && (
                        <div className="back-container" onClick={handleBack} onMouseDown={(event) => event.preventDefault()}>
                            <BackIcon className="back-icon" />
                            <span className="back-text">{t('productfinder:back')}</span>
                        </div>
                    )}
                    <span className="title">{title || defaultTitle}</span>
                </div>
            </div>
            { percentage >= 0 && (
                <div className={styles.progressBar} style={{ opacity: `${percentage === 0 ? '0' : '1'}`, width: `${percentage}%`, transition: '.7s ease-out' }}>
                    <span>
                        {percentage}
                        %
                    </span>
                </div>
            )}
        </div>
    );
};

export default Header;
