/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */

import useStyles from '@core_modules/productfinder/pages/default/components/Quiz/style';
import Button from '@common_button';
import Checkbox from '@material-ui/core/Checkbox';

const Quiz = (props) => {
    const {
        t, sliderIndex, data, handleNext, handleSkipPreQuiz, handleAnswer, isLastQuiz,
    } = props;
    const {
        title, description, answers, type, another_info, quiz_id, required, layout,
    } = data;
    const styles = useStyles();

    const disableContinue = !answers.some((answer) => answer.selected);
    const isPrequiz = answers.length === 0;
    const templateClassMapping = {
        'block-column': styles.templateBlockColumn,
        'block-row': styles.templateBlockRow,
        chip: styles.templateChip,
        square: styles.templateSquare,
    };
    let chipClassname;
    if (layout === 'chip') {
        chipClassname = (answers.length === 2 || answers.length === 3) ? `col-${answers.length}` : 'col-multiple';
    }

    const handleSelect = (value) => {
        handleAnswer(quiz_id, value);
    };

    return (
        <>
            { isPrequiz ? (
                <div>
                    <h1 className={styles.title}>{title}</h1>
                    { data.description && (
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: data.description }} />
                    )}
                    <Button
                        className={styles.continueButton}
                        onClick={() => handleNext(sliderIndex)}
                    >
                        {t('productfinder:continue')}
                    </Button>
                    {!required && (
                        <Button
                            className={styles.skipButton}
                            variant="text"
                            onClick={handleSkipPreQuiz}
                            disableRipple
                        >
                            {t('productfinder:skip')}
                        </Button>
                    )}
                    {another_info && <div className={styles.anotherInfo} dangerouslySetInnerHTML={{ __html: another_info }} />}
                </div>
            ) : (
                <div className={templateClassMapping[layout]}>
                    <div>
                        <h1 className={styles.title}>{title}</h1>
                        { description && (
                            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
                        )}
                    </div>
                    <div className={`options-container ${layout === 'chip' ? chipClassname : ''}`}>
                        {answers.map((answer) => (
                            <div
                                className={answer.selected ? 'option selected' : 'option'}
                                onClick={() => handleSelect(answer.value)}
                            >
                                {answer.image && (
                                    <img
                                        className="option-img"
                                        src={answer.image || '/assets/img/placeholder.png'}
                                        alt={answer.description}
                                    />
                                )}
                                {layout === 'block-row' && type === 'multiselect' ? (
                                    <div className="option-multiselect-container">
                                        <Checkbox
                                            checked={answer.selected}
                                            className="option-checkbox"
                                            color="primary"
                                            disableRipple
                                        />
                                        <p className="option-description">{answer.description}</p>
                                    </div>
                                ) : (
                                    <>
                                        {layout === 'block-column' && type === 'multiselect' && <Checkbox checked={answer.selected} className="option-checkbox" color="primary" disableRipple />}
                                        <p className="option-description">{answer.description}</p>
                                    </>
                                )}
                                {answer.note && <p className="option-note">{answer.note}</p>}
                            </div>
                        ))}
                    </div>
                    <Button
                        className={styles.continueButton}
                        onClick={() => handleNext(sliderIndex)}
                        disabled={disableContinue}
                    >
                        {isLastQuiz ? t('productfinder:viewMyResult') : t('productfinder:continue')}
                    </Button>
                    {!required && (
                        <Button
                            className={styles.skipButton}
                            variant="text"
                            onClick={() => handleNext(sliderIndex)}
                            disableRipple
                        >
                            {t('productfinder:skip')}
                        </Button>
                    )}
                    {another_info && <div className={styles.anotherInfo} dangerouslySetInnerHTML={{ __html: another_info }} />}
                </div>
            )}
        </>
    );
};
export default Quiz;
