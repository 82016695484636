/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        marginTop: 200,
        width: '100%',
        '& h1': {
            textAlign: 'center',
        },
    },
    container: {
        width: '90%',
        maxWidth: 600,
        margin: 'auto',
        marginTop: 210,
        minHeight: '80vh',
        [theme.breakpoints.down('1250')]: {
            marginTop: 260,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 80,
        },
        '& img': {
            maxWidth: '100%',
        },
    },
}));

const Result = (props) => {
    const { t, cmsData } = props;
    const styles = useStyles();

    if (cmsData && cmsData.cmsBlocks && cmsData.cmsBlocks.items[1] && cmsData.cmsBlocks.items[1].content) {
        return <div className={styles.container} dangerouslySetInnerHTML={{ __html: cmsData.cmsBlocks.items[1].content }} />;
    }
    return (
        <div className={styles.container}>
            <div className={styles.alertContainer}>
                <h1>{`CMS Block ${t('productfinder:notFound')}`}</h1>
            </div>
        </div>
    );
};
export default Result;
